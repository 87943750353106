<template>
  <section>
    <vx-card class="cardx thead">
      <form>
        <div class="vx-row">
          <div class="vx-col w-full m-auto md:w-2/3 lg:w-1/3">
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="mt-4">
                  <label class="vs-input--label">Type Of Reports</label>
                  <v-select
                    v-model="report_type"
                    :options="reportOptions"
                    :clearable="false"
                    v-validate="'required'"
                    name="report_type"
                  />
                  <span
                    class="text-danger text-sm block mt-2"
                    v-show="errors.has('report_type')"
                  >
                    {{ errors.first('report_type') }}
                  </span>
                </div>
              </div>

              <div class="vx-col w-full md:w-1/2">
                <div class="mt-4">
                  <label class="vs-input--label">Date Start</label>
                  <datepicker
                    v-model="form.date_start"
                    name="date_start"
                    format="dd MMM yyyy"
                    :minimumView="'day'"
                    :maximumView="'month'"
                    :disabledDates="disabledEndDates"
                  />
                </div>
              </div>

              <div class="vx-col w-full md:w-1/2">
                <div class="mt-4">
                  <label class="vs-input--label">Date End</label>
                  <datepicker
                    v-model="form.date_end"
                    name="date_end"
                    format="dd MMM yyyy"
                    :minimumView="'day'"
                    :maximumView="'month'"
                    :disabledDates="disabledStartDates"
                  />
                </div>
              </div>

              <div class="vx-col w-full" v-if="sitesVisible">
                <div class="mt-4">
                  <label class="vs-input--label">Club</label>

                  <div class="w-full mt-2">
                    <vs-checkbox
                      v-model="form.club"
                      vs-value="blue"
                      class="inline-flex"
                      color="primary"
                      name="types"
                      v-validate="'required'"
                    >
                      Blue Club
                    </vs-checkbox>
                  </div>

                  <span
                    class="text-danger text-sm block mt-2"
                    v-show="errors.has('clubs')"
                  >
                    {{ errors.first('clubs') }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Save & Reset Button -->
            <div class="vx-row">
              <div class="vx-col w-full mt-6">
                <div class="flex flex-wrap justify-begin">
                  <vs-button
                    icon-pack="feather"
                    icon="icon-download"
                    @click.prevent="generateReport"
                    :disabled="locked || !hasErrors || is_generating"
                  >
                    Generate Report
                  </vs-button>
                </div>
              </div>
            </div>

            <vs-progress
              indeterminate
              color="success"
              v-if="is_generating"
            ></vs-progress>
          </div>
        </div>
      </form>
    </vx-card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import FileSaver from 'file-saver'

// import Pusher from 'pusher-js'
// import Echo from 'laravel-echo'

import constants from '@/utils/constants'
import infoMixin from '@/mixins/infoMixin'

// window.Pusher = require('pusher-js')
//window.Pusher.logToConsole = true
import '@/laravelEcho'

export default {
  mixins: [infoMixin],
  components: {
    Datepicker,
  },
  data: () => ({
    reportOptions: constants.reportOptions,
    // schema: ,
    report_type: null,
    form: {
      report_type: null,
      club: ['blue'],
      date_start: null,
      date_end: null,
    },
    is_generating: false,
    generated_result: null,
    channel: null,
  }),

  computed: {
    ...mapGetters('report', {
      uuid: 'uuid',
    }),

    sitesVisible () {
      return (
        !this.form.report_type ||
        !['agent-performance'].includes(this.form.report_type)
      )
    },

    disabledEndDates () {
      if (!this.lodash.isEmpty(this.form.date_end)) {
        return {
          from: moment(this.form.date_end)
            .endOf('month')
            .toDate(),
        }
      }

      return {
        from: moment().toDate(),
      }
    },
    disabledStartDates () {
      if (!this.lodash.isEmpty(this.form.date_start)) {
        return {
          to: moment(this.form.date_start)
            .endOf('month')
            .toDate(),
        }
      }

      return {
        from: moment().toDate(),
      }
    },
  },

  methods: {
    ...mapActions('report', ['generate', 'download', 'reset']),

    async validateForm () {
      const validates = [this.$validator.validateAll()]

      const valids = await Promise.all(validates)

      return valids.every((valid) => valid)
    },
    async generateReport () {
      const valid = await this.validateForm()

      /* eslint-disable */
      if (!valid) {
        this.$vs.notify({
          title: 'Error',
          text: 'Please complete this form',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'warning',
        })
        return
      }

      this.locked = true

      await this.generate({ ...this.form })
        .then(() => {
          // this.$vs.notify({
          //   title: 'Success',
          //   text: 'Request reports has been sent',
          //   iconPack: 'feather',
          //   icon: 'icon-check-circle',
          //   color: 'success'
          // })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error',
            text: 'Request reports fail',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger',
          })
        })
        .finally(() => {
          this.locked = false
        })
    },

    async downloadReport(uuid) {
      this.locked = true

      await this.download({ uuid })
        .then((response) => {
          const disposition = this.lodash.get(
            response,
            'headers.content-disposition'
          )

          let fileName = 'report.xlsx'
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(disposition)
            if (matches !== null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '')
            }
          }

          FileSaver.saveAs(new Blob([response.data]), fileName)

          this.reset()

          this.$vs.notify({
            title: 'Success',
            text: 'Report has been generated',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error',
            text: 'Report unable to generate',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger',
          })
        })
        .finally(() => {
          this.locked = false
          this.generated_result = null
        })
    },
  },

  mounted() {
    this.reset()

    const self = this

    window.Echo.channel('reports')
      .listen('.generated', function(data) {
        if (self.lodash.isEmpty(self.uuid)) {
          self.generated_result = data
        }
        if (
          !self.lodash.isEmpty(self.uuid) &&
          self.lodash.get(data, 'uuid') === self.uuid
        ) {
          self.downloadReport(self.uuid)
        }
        // app.messages.push(JSON.stringify(data));
      })
      .listen('.exception', function(data) {
        if (
          !self.lodash.isEmpty(self.uuid) &&
          self.lodash.get(data, 'uuid') === self.uuid
        ) {
          self.reset()

          self.$vs.notify({
            title: 'Exception',
            text: self.lodash.get(data, 'message', 'Unable to generate'),
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger',
          })
        }
      })
  },

  beforeDestroy() {
    window.Echo.leaveChannel('reports')
  },

  watch: {
    report_type(to) {
      this.form.report_type = this.lodash.get(to, 'value')
    },
    uuid(to) {
      this.is_generating = !this.lodash.isEmpty(to)

      if (
        !this.lodash.isEmpty(this.uuid) &&
        !this.lodash.isEmpty(this.generated_result) &&
        this.lodash.get(this.generated_result, 'uuid') === this.uuid
      ) {
        this.downloadReport(this.uuid)
      }
    },
  },
}
</script>
